<template>
    <v-container>
        <v-row>
            <v-col class="pt-0">
                <h2 class="primary--text"> Mes options </h2>
            </v-col>
        </v-row>

        <!-- Quick search and pagination -->
        <v-row class="pb-4" align="center" no-gutters>
            <v-col cols="12" md="2" class="mb-2 mb-md-0">
                <v-text-field v-model="quickSearch" @keydown.enter="fetchOptions(true)" label="Recherche..." dense hide-details outlined clearable>
                    <template v-slot:append>
                        <v-icon color="grey" @click="fetchOptions(true)"> fas fa-search </v-icon>
                    </template>
                </v-text-field>
            </v-col>

            <v-col cols="12" md="5">
                <v-btn class="mr-4 ml-0 ml-md-4 mb-2 mb-md-0" @click="displayAdvanceSearch = !displayAdvanceSearch" color="primary" outlined small>
                    <v-icon v-if="!displayAdvanceSearch" left x-small> fas fa-plus </v-icon>
                    <v-icon v-if="displayAdvanceSearch" left x-small> fas fa-minus </v-icon>
                    de filtres
                </v-btn>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn small text class="grey lighten-2 mr-4 mb-2 mb-md-0" v-on="on">
                            {{ optionsCount }}
                        </v-btn>
                    </template>
                    Nombre total d'options
                </v-tooltip>

                <CreateOptionDialog @reload="fetchOptions(false, false)" isOption :initProgramId="this.programs[0]">
                    <template v-slot:activator="{ on: menu }">
                        <v-btn color="primary" class="mb-2 mb-md-0" depressed small dark v-on="{...menu}">
                            <v-icon x-small left>
                                fas fa-clock
                            </v-icon>
                            Poser une option
                        </v-btn>
                    </template>
                </CreateOptionDialog>
            </v-col>

            <v-col cols="12" md="5" class="text-right">
                <v-select class="sort-pagination d-inline-flex" v-model="order" label="Trier par" :menu-props="{ bottom: true, offsetY: true }" :items="optionsSortItems" background-color="white" @change="fetchOptions(true)" hide-details outlined dense>
                    <template v-slot:append-outer>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="toggleOrder()" icon v-on="on" class="ml-2">
                                    <v-icon v-bind:class="{'fa-rotate-180': by === 'asc'}">
                                        fas {{ by === 'asc' ? 'fa-sort-amount-down-alt': 'fa-sort-amount-down' }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <template v-if="by === 'asc'">
                                Croissant
                            </template>
                            <template v-if="by === 'desc'">
                                Décroissant
                            </template>
                        </v-tooltip>
                    </template>
                </v-select>

                <v-pagination v-model="page" @input="fetchOptions(true, false)" :length="totalPages" :total-visible="7" class="pagination d-inline-block" color="primary" />
            </v-col>
        </v-row>

        <!-- Advance search -->
        <v-expand-transition>
            <v-card v-show="displayAdvanceSearch" elevation="0">
                <v-sheet color="grey lighten-3" class="pa-4 mb-4">
                    <span class="caption grey--text text--darken-1"> Option </span>
                    <v-row>
                        <v-col cols="3">
                            <OptionStatusSelect v-model="statusesFilter" multiple />
                        </v-col>

                        <v-col cols="3">
                            <DatePickerMenu v-model="updatedDateFilter" label="Date de mise à jour" range />
                        </v-col>

                        <v-col cols="3">
                            <ContactAutocomplete v-model="contactsFilter" multiple />
                        </v-col>
                    </v-row>

                    <span class="caption grey--text text--darken-1"> Lot </span>
                    <v-row>
                        <v-col cols="3">
                            <ProgramAutocomplete ref="programAutocomplete" v-model="programsFilter" label="Programmes" :disabled="embeded" multiple noDynamicSearch />
                        </v-col>

                        <v-col cols="3">
                            <ProductAutocomplete ref="productAutocomplete" v-model="productsFilter" multiple noDynamicSearch />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2">
                            <v-btn color="primary" class="mr-4" dark depressed small @click="fetchOptions(true)">
                                <v-icon left x-small> fas fa-search </v-icon>
                                Rechercher
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-card>
        </v-expand-transition>

        <v-data-table class="elevation-2" :headers="headers" :items="options" item-key="id" hide-default-footer :items-per-page="-1" :show-expand="false" :single-expand="false">
            <template v-slot:item.product="{ item }">
                <ProductDialog :productId="item.product.id">
                    <template v-slot:activator="{ on: modal }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-chip :color="getOptionStatus(item.optionstatus, 'color')" label small dark class="my-1" v-on="{ ...tooltip, ...modal }">
                                    <span v-if="item.product.reference">
                                        {{ item.product.reference }}
                                    </span>
                                    <span v-else>
                                        <em> Sans référence </em>
                                    </span>

                                    <v-icon right> {{ getOptionStatus(item.optionstatus, 'icon') }} </v-icon>
                                </v-chip>
                            </template>
                            {{ getOptionStatus(item.optionstatus, 'label') }}
                        </v-tooltip>
                    </template>
                </ProductDialog>

                <br>

                {{ item.product.typeLabel + ' ' + item.product.roomsLabel }}
                <template v-if="item.product.surface">
                    {{ item.product.surface | round() }} m²
                </template>

                <br>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-chip class="mb-1 mr-2" small label v-on="on">
                            {{ item.date | toDate() }}
                        </v-chip>
                    </template>
                    Date de pose de l'option
                </v-tooltip>

                <v-tooltip v-if="item.statusdate" top>
                    <template v-slot:activator="{ on }">
                        <v-chip class="mb-1" :color="getOptionStatus(item.optionstatus, 'color')" dark small label v-on="on">
                            {{ item.statusdate | toDate() }}
                        </v-chip>
                    </template>
                    {{ item.statusdatelabel }}
                </v-tooltip>
            </template>

            <template v-slot:item.contact="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <router-link :to="`/contacts/${item.contact.id}`" class="no-text-decoration cursor-pointer" v-on="on">
                            <v-chip label small class="mt-1 cursor-pointer" color="contact-background">
                                <v-icon color="contact-foreground" x-small class="mr-1"> fas fa-user </v-icon>

                                {{ item.contact.name }} {{ item.contact.firstname }}
                            </v-chip>
                        </router-link>
                    </template>
                    Nom / Prénom
                </v-tooltip>

                <br>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <span v-if="item.contact.email" v-on="on">
                            <v-icon color="contact-foreground" x-small class="mr-1"> fas fa-envelope </v-icon>
                            {{ item.contact.email }}
                        </span>
                    </template>
                    Email
                </v-tooltip>

                <br>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <span v-if="item.contact.mobile" v-on="on">
                            <v-icon color="contact-foreground" x-small class="mr-1"> fas fa-mobile-alt </v-icon>
                            {{ item.contact.mobile }}
                        </span>
                    </template>
                    Portable
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <span v-if="item.contact.phone" v-on="on">
                            <v-icon color="contact-foreground" x-small class="mr-1"> fas fa-phone </v-icon>
                            {{ item.contact.phone }}
                        </span>
                    </template>
                    Téléphone fixe
                </v-tooltip>
            </template>

            <template v-slot:item.prices="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <span v-on="on" class="blue--text text--darken-4 font-weight-bold mx-0 px-0">
                            {{ item.amount | toCurrencyString() }}
                        </span>
                    </template>
                    Prix total
                </v-tooltip>

                <br>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <span v-on="on">
                            {{ item.vat | round(1) }} %
                        </span>
                    </template>
                    Taux TVA
                </v-tooltip>
            </template>

            <template v-slot:item.program="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <router-link :to="`/programmes/${item.program.id}`" class="no-text-decoration">
                            <v-chip v-text="item.program.title" label small v-on="on" class="cursor-pointer" />
                        </router-link>
                    </template>
                    Référence programme {{ item.program.reference }}
                </v-tooltip>

                <br />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <span v-on="on">
                            {{ item.program.city }}
                        </span>
                    </template>
                    Ville
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <span v-on="on">
                            {{ item.program.zip }}
                        </span>
                    </template>
                    Code postal
                </v-tooltip>
                <br>
                <br>
            </template>

            <template v-slot:item.actions="{ item }">
                <OptionToSaleDialog v-if="canConvertOptionToSale(item.optionstatus)" @reload="fetchOptions(false, false)" :optionId="item.id">
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn color="primary" class="mb-1 mr-1" depressed x-small dark v-on="{ ...menu, ...tooltip }">
                                    <v-icon x-small> fas fa-shopping-cart </v-icon>
                                </v-btn>
                            </template>
                            Transformer l'option en réservation
                        </v-tooltip>
                    </template>
                </OptionToSaleDialog>

                <ConfirmOptionDialog v-if="canConfirmOption(item.optionstatus)" @reload="fetchOptions(false, false)" :optionId="item.id" :expirationDate="item.expiredate">
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn color="primary" depressed x-small class="mb-1 mr-1" dark v-on="{...menu, ...tooltip}">
                                    <v-icon x-small> fas fa-check-square </v-icon>
                                </v-btn>
                            </template>
                            Confirmer l'option
                        </v-tooltip>
                    </template>
                </ConfirmOptionDialog>

                <CancelOptionDialog v-if="canCancelOption(item.optionstatus)" @reload="fetchOptions(false, false)" :optionId="item.id">
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn color="primary" depressed x-small class="mb-1 mr-1" dark v-on="{...menu, ...tooltip}">
                                    <v-icon x-small> fas fa-times-circle </v-icon>
                                </v-btn>
                            </template>
                            Annuler l'option
                        </v-tooltip>
                    </template>
                </CancelOptionDialog>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import optionsMixin from '../mixins/options';
import productsMixin from '../mixins/products.js';
import ProductDialog from '../components/products/ProductDialog.vue';
import DatePickerMenu from '../components/widgets/DatePickerMenu.vue';
import CancelOptionDialog from '../components/options/CancelOptionDialog.vue';
import CreateOptionDialog from '../components/dialogs/CreateOptionDialog.vue';
import OptionStatusSelect from '../components/widgets/OptionStatusSelect.vue';
import OptionToSaleDialog from '../components/options/OptionToSaleDialog.vue';
import ConfirmOptionDialog from '../components/options/ConfirmOptionDialog.vue';
import ContactAutocomplete from '../components/widgets/ContactAutocomplete.vue';
import ProductAutocomplete from '../components/widgets/ProductAutocomplete.vue';
import ProgramAutocomplete from '../components/widgets/ProgramAutocomplete.vue';

export default {
    name: 'Options',

    mixins: [optionsMixin, productsMixin],

    props: {
        programs: { type: Array, default: () => ([]) },
        embeded: { type: Boolean, default: false }
    },

    components: {
        ProductDialog,
        DatePickerMenu,
        CancelOptionDialog,
        CreateOptionDialog,
        OptionStatusSelect,
        OptionToSaleDialog,
        ConfirmOptionDialog,
        ContactAutocomplete,
        ProductAutocomplete,
        ProgramAutocomplete
    },

    data: () => ({
        options: [],
        page: 1,
        limit: 10,
        totalPages: 1,
        optionsCount: 0,
        order: 'statutsdate',
        by: 'desc',

        disableNavigation: false,

        quickSearch: '',
        programsFilter: [],
        productsFilter: [],
        statusesFilter: [],
        contactsFilter: [],
        updatedDateFilter: [],

        displayAdvanceSearch: false,
        advanceSearchDataFetched: false,

        optionsSortItems: [
            { text: 'Date de pose', value: 'date' },
            { text: 'Date d\'annulation', value: 'canceleddate' },
            { text: 'Date de mise à jour', value: 'statutsdate' },
            { text: 'Date de confirmation', value: 'confirmationDate' },
            { text: 'Nom du prospect', value: 'contact.name' },
            { text: 'Référence du lot', value: 'product.reference' }
        ],

        headers: [
            {
                text: 'Lot',
                value: 'product',
                sortable: false
            },
            {
                text: 'Contact',
                value: 'contact',
                sortable: false
            },
            {
                text: 'Montants',
                value: 'prices',
                sortable: false,
                align: 'end'
            },
            {
                text: 'Programme',
                value: 'program',
                sortable: false
            },
            {
                text: 'Actions',
                value: 'actions',
                align: 'center',
                sortable: false,
                width: '15%'
            }
        ]
    }),

    methods: {
        buildQuery() {
            const query = {
                page: this.page,
                limit: this.limit,
                orderBy: `${this.order}:${this.by}`
            };
            if (Array.isArray(this.programsFilter) && this.programsFilter.length > 0) {
                query.programs = this.programsFilter.join(',');
            }
            if (Array.isArray(this.productsFilter) && this.productsFilter.length > 0) {
                query.products = this.productsFilter.join(',');
            }
            if (Array.isArray(this.statusesFilter) && this.statusesFilter.length > 0) {
                query.statuses = this.statusesFilter.join(',');
            }
            if (Array.isArray(this.contactsFilter) && this.contactsFilter.length > 0) {
                query.contacts = this.contactsFilter.join(',');
            }
            if (Array.isArray(this.updatedDateFilter) && this.updatedDateFilter.length === 2) {
                query.updatedDate = `${this.updatedDateFilter[0]},${this.updatedDateFilter[1]}`;
            }
            if (this.quickSearch) {
                query.quickSearch = this.quickSearch;
            }
            return query;
        },

        buildDisplayQuery() {
            const query = {};
            if (this.page !== 1) {
                query.page = this.page;
            }
            if (Array.isArray(this.programsFilter) && this.programsFilter.length > 0) {
                query.programs = this.programsFilter.join(',');
            }
            if (Array.isArray(this.productsFilter) && this.productsFilter.length > 0) {
                query.products = this.productsFilter.join(',');
            }
            if (Array.isArray(this.statusesFilter) && this.statusesFilter.length > 0) {
                query.statuses = this.statusesFilter.join(',');
            }
            if (Array.isArray(this.contactsFilter) && this.contactsFilter.length > 0) {
                query.contacts = this.contactsFilter.join(',');
            }
            if (Array.isArray(this.updatedDateFilter) && this.updatedDateFilter.length === 2) {
                query.updatedDate = `${this.updatedDateFilter[0]},${this.updatedDateFilter[1]}`;
            }
            if (this.quickSearch) {
                query.quickSearch = this.quickSearch;
            }
            if (this.order !== 'statutsdate') {
                query.order = this.order;
                query.by = this.by;
            }
            return query;
        },

        parseQuery(query) {
            this.page = parseInt(query.page) || 1;
            this.limit = parseInt(query.limit) || 10;
            this.programsFilter = query.programs ? query.programs.split(',').map((p) => parseInt(p)) : [];
            this.productsFilter = query.products ? query.products.split(',').map((p) => parseInt(p)) : [];
            this.statusesFilter = query.statuses ? query.statuses.split(',') : [];
            this.contactsFilter = query.contacts ? query.contacts.split(',').map((s) => parseInt(s)) : [];
            this.quickSearch = query.quickSearch || null;
            this.updatedDateFilter = query.updatedDate ? query.updatedDate.split(',') : [];
            this.order = query.order ? query.order : this.order;
            this.by = query.by ? query.by : this.by;
        },

        doPush(query) {
            return (Object.keys(query).length !== Object.keys(this.$route.query).length) || !Object.keys(query).every((key) => query[key] === this.$route.query[key]);
        },

        async fetchOptions(push, resetPage = true) {
            try {
                this.disableNavigation = true;
                this.setLoading(true);

                if (resetPage) {
                    this.page = 1;
                }

                if (!this.embeded && push && this.doPush(this.buildDisplayQuery())) {
                    this.$router.push({ query: this.buildDisplayQuery() });
                }

                const query = this.buildQuery();
                const { options, count, totalPages } = await this.repos.options.getOptions(query);
                this.options = options;
                this.optionsCount = count;
                this.totalPages = totalPages;
            } catch (err) {
                console.error(err);
            } finally {
                this.disableNavigation = false;
                this.setLoading(false);
            }
        },

        toggleOrder() {
            this.by = this.by === 'asc' ? 'desc' : 'asc';
            this.fetchOptions(true);
        }
    },

    watch: {
        $route(to) {
            if (!this.disableNavigation) {
                if (!this.embeded) {
                    this.parseQuery(to.query);
                }
                this.fetchOptions(false);
            }
        },

        displayAdvanceSearch() {
            if (this.displayAdvanceSearch && !this.advanceSearchDataFetched) {
                this.advanceSearchDataFetched = true;
                this.$refs.programAutocomplete.fetchPrograms({ limit: 1000 });
                this.$refs.productAutocomplete.fetchProducts({
                    limit: 1000,
                    productsOptionsPartner: 1
                });
            }
        }
    },

    created() {
        if (this.embeded) {
            this.programsFilter = this.programs;
            this.headers = this.headers.filter((h) => h.value !== 'program');
        } else {
            this.parseQuery(this.$route.query);
        }
        this.fetchOptions(false);
    }
};
</script>


<style lang="scss" scoped>
.pagination::v-deep .v-pagination {
    .v-pagination__navigation:last-child {
        margin-right: 0;
    }
}

.sort-pagination::v-deep {
    .v-input__control {
        max-width: 300px;
    }

    .v-input__append-outer {
        margin: 0 !important;
    }
}
</style>
